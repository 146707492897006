<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {

    }
  },
  methods:{
    /**
     * 获取url参数
     */
    toWx() {
      window.wx.miniProgram.navigateTo({
        url: '/pages/paying/paying?orderId='+ this.getQueryString('orderId') + '&uid='+ this.getQueryString('uid')
      })
    },
    /**
     * 获取路径所以参数
     */
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]); return null;
    }
  },
  created() {
  },
  mounted() {
    this.toWx()
  },
  computed:{
  },
  watch:{
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
